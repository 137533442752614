import { useMemo, useState } from 'react';
import { Square, Record } from 'phosphor-react';
import Colors from 'okadoc-component-ui/lib/Colors';
import { Constants, useMeeting } from '@videosdk.live/react-sdk';
import getRecordingConfig from 'libs/recording-config';
import { ToolboxButton, ToolboxIcon } from './styles';
import RecordConfirmationModal from './record-confirmation-modal';

const RecordMeeting = ({ shallRecord = false, enableRecording = false, autoRecording = false }) => {
  const [isShowRecordConfirmationModal, setIsShowRecordConfirmationModal] = useState(false);
  const [isButtonStopClicked, setisButtonStopClicked] = useState(false);
  const [isButtonStartClicked, setisButtonStartClicked] = useState(false);
  const recordingConfig = getRecordingConfig();

  const handleRecordingError = data => {
    const { code } = data;
    if (code === 5001) {
      meeting?.startRecording(null, null, recordingConfig);
    }
  };

  const meeting = useMeeting({
    onerror: handleRecordingError
  });

  const toggleShowRecordConfirmationModal = () => {
    setIsShowRecordConfirmationModal(value => !value);
  };

  const doStartRecording = () => {
    setisButtonStartClicked(true);
    if (!shallRecord || isButtonStopClicked) {
      meeting?.startRecording(null, null, recordingConfig);
      toggleShowRecordConfirmationModal();
    }
  };

  const handleClickStopRecording = () => {
    setisButtonStartClicked(false);
    if (autoRecording) {
      setisButtonStopClicked(true);
    }
    meeting?.stopRecording();
  };

  const isRecording = useMemo(
    () =>
      meeting?.recordingState === Constants.recordingEvents.RECORDING_STARTED ||
      meeting?.recordingState === Constants.recordingEvents.RECORDING_STOPPING,
    [meeting?.recordingState]
  );

  const handleToggleRecordConfirmationModal = () => {
    if ((autoRecording && !isButtonStopClicked) || isButtonStartClicked) {
      return;
    }
    toggleShowRecordConfirmationModal();
  };

  if (!meeting || !enableRecording) {
    return null;
  }

  return (
    <div className="toolbox-button-container">
      {isRecording && (
        <ToolboxButton $isRecording aria-label="Stop Recording" role="button">
          <ToolboxIcon className="d-flex justify-content-center align-items-center">
            <Square
              className="recording-icon"
              onClick={handleClickStopRecording}
              size={24}
              weight="fill"
              color={Colors.text.WhiteText}
            />
          </ToolboxIcon>
        </ToolboxButton>
      )}
      {!isRecording && (
        <ToolboxButton
          $isAutoRecording={(autoRecording && !isButtonStopClicked) || isButtonStartClicked}
          aria-label="Start Recording"
          role="button"
        >
          <ToolboxIcon className="d-flex justify-content-center align-items-center">
            <Record
              className="recording-icon"
              onClick={handleToggleRecordConfirmationModal}
              size={24}
              color={Colors.text.WhiteText}
            />
          </ToolboxIcon>
        </ToolboxButton>
      )}
      <RecordConfirmationModal
        open={isShowRecordConfirmationModal}
        onHide={toggleShowRecordConfirmationModal}
        onSubmit={doStartRecording}
      />
    </div>
  );
};

export default RecordMeeting;
