import styled, { css } from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const ToolboxButton = styled.div`
  color: ${Colors.text.WhiteText};
  cursor: ${({ $isAutoRecording }) => ($isAutoRecording ? 'not-allowed' : 'pointer')};
  display: inline-block;
  line-height: 48px;
  text-align: center;
  border-radius: 3px;
  ${({ $isRecording }) =>
    $isRecording &&
    css`
      background-color: #e09347;
    `}
  ${({ $isAutoRecording }) =>
    $isAutoRecording &&
    css`
      background-color: ${Colors.grayscale.RhinoLight};
    `}
`;

export const ToolboxIcon = styled.div`
  position: relative;
  flex-direction: column;
  border-radius: 3px;
  font-size: 24px;
  height: 48px;
  width: 48px;
`;
