import { Circle } from 'phosphor-react';
import Colors from 'okadoc-component-ui/lib/Colors';
import { Container } from './styles';

const RecordSign = ({ isShowingSmallContainer }) => {
  return (
    <Container
      isShowingSmallContainer={isShowingSmallContainer}
      className="d-flex justify-content-center align-items-center"
    >
      <Circle size={18} color={Colors.text.WhiteText} weight="fill" />
      <span>REC</span>
    </Container>
  );
};

export default RecordSign;
