import Colors from 'okadoc-component-ui/lib/Colors';
import styled from 'styled-components';

export const Container = styled.div`
  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
  }
  width: 61px;
  height: 28px;
  background-color: ${Colors.feedback.FireRed};
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.text.WhiteText};
  position: absolute;
  top: ${props => (props.isShowingSmallContainer ? '65px' : '75px')};
  left: ${props => (props.isShowingSmallContainer ? '10px' : '26px')};
  padding: 2px 5px;
  animation: fadeInOut 2s ease-in-out infinite;
  > span {
    margin-left: 4px;
  }
`;
